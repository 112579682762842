<template>
  <div class="wap-setting user">
    <headerBar :title="$t('she-zhi-0')" @back="back"></headerBar>
    <div class="menu-list">
      <div
        class="setting-line flex-center-between"
        v-for="(item, i) in menuList"
        :key="i"
        @click="toPath(item.path)"
      >
        <div class="flex-center">
          <span>{{ item.name }}</span>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div>
      <Button class="logout-btn" @click="logout">{{ $t('tui-chu') }}</Button>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { Button } from 'vant'
import { removeToken } from '@/utils/auth'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {}
  },
  computed: {
    menuList() {
      return [
        {
          name: this.$t('deng-lu-mi-ma'),
          path: 'wapUserEditPassword'
        },
        {
          name: this.$t('zi-jin-mi-ma'),
          path: 'wapUserEditSafePassword'
        },
        {
          name: this.$t('shou-huo-di-zhi'),
          path: 'wapAddress'
        }
      ]
    }
  },
  methods: {
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    back() {
      this.$router.go(-1)
    },
    logout() {
      removeToken()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'wapUserLogin'
      })
    }
  }
}
</script>